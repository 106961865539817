<template>
  <vDialog :visible.sync="visible" :visibleFooter="false" :top="'0'" additionalClass="app-dialog">
    <i class="el-icon-close closeButton" @click="visible = false" />
    <img src="@/assets/images/home/app.png"/>
  </vDialog>
</template>

<script>
import vDialog from './vDialog';

export default {
  name: 'App',
  props: {
    appVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    appVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:appVisible', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/app.scss';
</style>
