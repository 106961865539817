export default {
  data() {
    return {
      hideMT4IdList: [],
      hideMT5IdList: [],
    }
  },
  methods: {
    onHideMT4Account() {
      const getHideIdList = this.mt4AccountTableData.filter(f => this.hideMT4IdList.includes(f.id)).map(m => m.id)
      this.hideAccount(getHideIdList)
      this.hideMT4IdList = []
    },
    onHideMT5Account() {
      const getHideIdList = this.mt5AccountTableData.filter(f => this.hideMT5IdList.includes(f.id)).map(m => m.id)
      this.hideAccount(getHideIdList)
      this.hideMT5IdList = []
    },
    hideAccount(getHideIdList) {
      this.queryMetaTraderAccountDetails(getHideIdList, false)
    },
    showAllAccount(platform) {
      this.queryMetaTraderAccountDetails([], true, platform)
    },
  },
}
