<template>
  <home :showLink="showLink">
    <template slot="alerts">
      <div class="content_title">
        <h2>{{ $t('menu.liveAcc') }}</h2>
        <p></p>
      </div>
      <PendingAction
        v-if="showIdentityProofAlert"
        v-bind:type="'identityProof'"
        v-on:clickedPendingAction="pendingActionResponse"
      ></PendingAction>
      <Notification
        v-if="pendingIDProofApproval && !showIdentityProofAlert"
        :id="pendingIDProofApproval"
        :poa="pendingAddressProofApproval"
      ></Notification>
      <UploadIdOrAddress
        :visible.sync="uploadIdOrAddress.visible"
        :idPendingReason="idPendingReason"
        :idCustomPendingReason="idCustomPendingReason"
        :poaPendingReason="poaPendingReason"
        :poaCustomPendingReason="poaCustomPendingReason"
        :idRecode="idRecode"
        :poaRecode="poaRecode"
      ></UploadIdOrAddress>
    </template>

    <div class="table_list_box">
      <div class="table_item_box">
        <!-- <p class="title">MetaTrader4</p> -->
        <div class="hideShowButton">
          <el-button class="purple_button" @click="onHideMT4Account()" data-testid="hideMT4Account">
            {{ $t('common.button.hide') }}
          </el-button>
          <el-button class="white_button" @click="showAllAccount()" data-testid="showAllMT4Account">
            {{ $t('common.button.showAll') }}
          </el-button>
        </div>
        <div class="table_box">
          <el-table :data="mt4AccountTableData">
            <el-table-column :label="$t('common.button.hide')" min-width="130">
              <template slot-scope="scope">
                <div class="hideAccountCheckbox">
                  <el-checkbox v-model="hideMT4IdList" :label="scope.row.id" :value="scope.row.id"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="mt4_account" :label="$t('common.column.accNum')" min-width="130"></el-table-column>
            <el-table-column prop="platform" :label="$t('common.column.platform')" min-width="130"></el-table-column>

            <el-table-column
              show-overflow-tooltip
              prop="server"
              :label="$t('common.column.server')"
              min-width="180"
            ></el-table-column>
            <el-table-column :label="$t('common.column.type')" min-width="100">
              <template slot-scope="scope">{{ $config.accountTypeMaps[scope.row.mt4_account_type] }}</template>
            </el-table-column>
            <el-table-column prop="currency" :label="$t('common.column.currency')" min-width="120"></el-table-column>
            <el-table-column :label="$t('common.column.equity')" min-width="120">
              <template slot-scope="scope">{{ scope.row.equity | formatNumber }}</template>
            </el-table-column>
            <el-table-column :label="$t('common.column.credits')">
              <template slot-scope="scope">
                <span v-if="scope.row.status === 'Active'">
                  {{scope.row.credit | formatNumber}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.balance')">
              <template slot-scope="scope">
                <span v-if="scope.row.status === 'Active'">
                  {{scope.row.balance | formatNumber}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.leverage')" min-width="150">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 'Active'">
                  <span class="leverageTextLeft">{{ scope.row.leverage }} : 1</span>
                  <div class="icon" v-if="!scope.row.isInBlackList">
                    <a class="paddingLeft" v-if="scope.row.leverageStatus == 1">
                      <img
                        src="@/assets/images/select_down.png"
                        @click.stop.prevent="leverageClick(scope.$index, scope.row)"
                        data-testid="leverageMt4"
                      />
                    </a>
                    <a class="paddingLeft" v-else>
                      <img
                        src="@/assets/images/label_icon.png"
                        @click.stop.prevent="showleverageAlreadyInProcessPopUp()"
                      />
                    </a>
                  </div>
                </div>
                <i v-else>-</i>
              </template>
            </el-table-column>
            <el-table-column v-if="!showDiffTable" :label="$t('common.column.status')" min-width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 'Active'" :class="'red'">{{ scope.row.status | accStatus }}</div>
                <div v-if="scope.row.status == 'Inactive'" :class="'gray'">
                  <i>{{ scope.row.status | accStatus }}</i>
                </div>
                <div v-if="scope.row.status == 'Processing' || scope.row.status == 'Rejected'" :class="'gray'">
                  <i>{{ scope.row.status | accStatus }}</i>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-else :label="$t('common.column.status')" min-width="100">
              <template slot-scope="scope">
                <div>
                  <span class="leverageTextLeft red">
                    <i>pending</i>
                  </span>
                  <div class="icon">
                    <a class="paddingLeft">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="
                          $t('home.pendingTooltip', {
                            platform: scope.row.platform
                          })
                        "
                        placement="bottom"
                      >
                        <img src="@/assets/images/label_icon.png" class="label_icon" />
                      </el-tooltip>
                    </a>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="!showDiffTable && !showDiffButton"
              :label="$t('common.column.resetPW')"
              min-width="170"
            >
              <template slot-scope="scope">
                <a
                  v-if="scope.row.status == 'Active'"
                  @click.stop.prevent="handleLock(scope.$index, scope.row)"
                  data-testid="resetMT4PW"
                >
                  <img class="lock_icon" src="@/assets/images/header/logout_icon.png" alt />
                </a>
                <i v-else>-</i>
              </template>
            </el-table-column>
            <el-table-column v-else-if="showDiffButton" :label="$t('common.button.depositFunds')" min-width="160">
              <el-button class="purple_button" @click="$router.push('depositFunds')" data-testid="depositFunds">
                {{ $t('common.button.depositFunds') }}
              </el-button>
            </el-table-column>
          </el-table>
          <!-- <div class="appTip">
            {{ $t('home.appNote.desc01', { platform: 'VT Markets' }) }}
            <span class="blue" @click="appVisible = true" data-testid="appDialog">
              {{ $t('home.appNote.desc02') }}</span
            >
          </div> -->
        </div>
      </div>
    </div>
    <template slot="components">
      <!-- CHANGE LEVERAGE 弹框开始 -->
      <ChangeLeverage
        :changeLeverageVisible.sync="changeLeverageVisible"
        :leverageConfirmVisible.sync="leverageConfirmVisible"
        :leverageChangeSubmitSuccess.sync="leverageChangeSubmitSuccess"
        :leverageChangeSubmitFail.sync="leverageChangeSubmitFail"
        :leverageChangeSubmitReject.sync="leverageChangeSubmitReject"
        :leverageChangeAuditSuccess.sync="leverageChangeAuditSuccess"
        :leverageChangeSubmitTimeOut.sync="leverageChangeSubmitTimeOut"
        @resetTable="queryMetaTraderAccountDetails()"
        method="live"
      ></ChangeLeverage>
      <!-- CHANGE LEVERAGE 弹框结束 -->

      <!-- CHANGE LEVERAGE confirm 弹框开始 -->
      <Common :homeVisible.sync="leverageConfirmVisible">
        <div class="dialog_body" v-if="leverageChangeSubmitSuccess">
          <img src="@/assets/images/dialog_true.png" alt />
          <p
            v-html="
              leverageChangeAuditSuccess
                ? $t('home.leverage.changeLeverageAutoComplete')
                : $t('home.leverage.changeLeverageConfirm')
            "
          ></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitFail">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageFail')"></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitReject">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageReject')"></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitTimeOut">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageDefault', { supportEmail: GLOBAL_CONTACT_EMAIL })"></p>
        </div>
      </Common>
      <!-- CHANGE LEVERAGE confirm 弹框结束 -->

      <!-- CHANGE LEVERAGE already 弹框开始 -->
      <Common :homeVisible.sync="leverageAlreadyVisible">
        <img src="@/assets/images/dialog_true.png" alt />
        <p>{{ $t('home.leverage.alreadyApplied', { platform: $config.info.fullName }) }}</p>
      </Common>
      <!-- CHANGE LEVERAGE already 弹框结束 -->

      <!-- 点击小锁弹框开始 -->
      <Password
        :passwordVisible.sync="passwordVisible"
        :forgotPwdVisible.sync="forgotPwdVisible"
        :number="number"
        :uuid="uuid"
        @forgotPwdClick="forgotPwdClick"
      ></Password>
      <!-- 点击小锁弹框结束 -->

      <!-- 点击 CHANGE PASSWORD  表单弹框开始 -->
      <ChangePassword
        :changePasswordVisible.sync="changePasswordVisible"
        :pwdConfirmVisible.sync="pwdConfirmVisible"
        :number="number"
        :isDemo="isDemo"
        @forgotPwdClick="forgotPwdClick"
      ></ChangePassword>
      <!-- 点击 CHANGE PASSWORD  表单弹框结束 -->

      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->
      <Common :homeVisible.sync="pwdConfirmVisible">
        <img src="@/assets/images/dialog_true.png" alt />
        <p v-html="$t('home.pw.confirm')"></p>
      </Common>
      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->

      <!-- FORGOT PASSWORD 开始 -->
      <Common :homeVisible.sync="forgotPwdVisible">
        <img src="@/assets/images/dialog_true.png" alt />
        <div v-html="$t('home.pw.email')"></div>
      </Common>
      <!-- FORGOT PASSWORD 结束 -->

      <!-- active 弹框开始 -->
      <Active
        :uuid="uuid"
        :reActivateMetaTraderLogin="reActivateMetaTraderLogin"
        :activateVisible.sync="activateVisible"
        :activateConfirmVisible.sync="activateConfirmVisible"
        @resetTable="queryMetaTraderAccountDetails()"
      ></Active>
      <!-- active 弹框结束 -->

      <!-- active Confirm 弹框开始 -->
      <Common :homeVisible.sync="activateConfirmVisible">
        <img src="@/assets/images/dialog_true.png" alt />
        <p v-html="$t('home.activate.confirm')"></p>
      </Common>
      <!-- active Confirm 弹框结束 -->

      <App :appVisible.sync="appVisible"></App>
    </template>
  </home>
</template>

<script>
import {
  apiQueryMetaTraderAccountDetails,
  apiQueryLeverageStatuses,
  apiReq_reset_mt4_password,
  apiCampaignInfo
} from '@/resource';
import App from '@/components/home/App';
import PendingAction from '@/components/home/PendingAction';
import Notification from '@/components/home/Notification';
import UploadIdOrAddress from '@/components/home/UploadIdAddress/UploadIdOrAddress';
import Common from '@/components/home/Common';
import Active from '@/components/home/Active';
import Password from '@/components/home/Password';
import ChangePassword from '@/components/home/ChangePassword';
import ChangeLeverage from '@/components/home/ChangeLeverage';
import Home from '@/components/template/Home';
import idPoaMixin from '@/mixins/idPoa';
import changeLeverageMixins from '@/mixins/page/home/changeLeverage';
import hideAccountMixins from '@/mixins/page/home/hideAccount';
import { mapState } from 'vuex';

export default {
  name: 'homePage',
  components: {
    App,
    PendingAction,
    Notification,
    UploadIdOrAddress,
    Common,
    Active,
    Password,
    ChangePassword,
    ChangeLeverage,
    Home
  },
  mixins: [idPoaMixin, changeLeverageMixins, hideAccountMixins],
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      uploadIdOrAddress: {
        visible: false
      },
      number: null,
      allMetaTraderAccounts: [],
      mt4AccountTableData: [],
      leverageStatus: {},
      leverageAlreadyVisible: false,
      passwordVisible: false,
      changePasswordVisible: false,
      // 小锁弹出框
      pwdConfirmVisible: false,
      forgotPwdVisible: false,
      // active 弹框
      activateVisible: false,
      activateConfirmVisible: false,
      reActivateMetaTraderLogin: null,
      isDemo: false,
      appVisible: false
    };
  },
  mounted() {
    this.queryMetaTraderAccountDetails();
  },
  computed: {
    idPass() {
      return this.$store.state.common.idPass;
    },
    showLink() {
      return this.allMetaTraderAccounts.length > 1;
    },
    showDiffTable() {
      return this.allMetaTraderAccounts.length == 1 && !this.idPass;
    },
    showDiffButton() {
      return this.allMetaTraderAccounts.length == 1 && !this.idPass && this.allMetaTraderAccounts[0].mt4_account != '-';
    },
    noDepositIsLogin() {
      return this.$store.state.common.noDepositIsLogin;
    },
    ...mapState('promotion', {
      eligibleCampaigns: 'eligibleCampaigns',
      participatedCampaigns: 'participatedCampaigns'
    })
  },
  watch: {
    eligibleCampaigns(newValue) {
      if (newValue.some(c => c.campaignType === 'NO_DEPOSIT_BONUS_2') && this.noDepositIsLogin) {
        let campaign = this.eligibleCampaigns.find(c => c.campaignType === 'NO_DEPOSIT_BONUS_2');
        this.getNoDespositBonusInfo(campaign.id);
      }
    },
    participatedCampaigns(newValue) {
      if (newValue.some(c => c === 10) && this.noDepositIsLogin) {
        let participatedCampaignID = this.participatedCampaigns.find(c => c === 10);
        this.getNoDespositBonusInfo(participatedCampaignID);
      }
    }
  },
  methods: {
    pendingActionResponse() {
      this.uploadIdOrAddress.visible = true;
    },
    queryMetaTraderAccountDetails(hiddenMtAccountIdList = [], unHide = false, platform = null) {
      apiQueryMetaTraderAccountDetails({
        hiddenMtAccountIdList: hiddenMtAccountIdList,
        unHide: unHide,
        platform: platform
      }).then(resp => {
        if (Array.isArray(resp.data)) {
          this.allMetaTraderAccounts = resp.data;
          this.formatAccounts();
          this.queryLeverageStatus();
        }
      });
    },
    formatAccounts() {
      this.allMetaTraderAccounts.forEach(element => {
        if (element.status != 1) {
          this.formatPendingAccount(element);
        }
        if (element.isArchive == 1 || element.isArchive == 2) {
          this.formatArchieveAccount(element);
        }
        this.setStatus(element);
      });
    },
    formatPendingAccount(account) {
      account.equity = '-';
      account.mt4_account = '-';
    },
    formatArchieveAccount(account) {
      account.equity = '-';
      account.currency = '-';
    },
    setStatus(account) {
      if (account.isArchive && (account.isArchive == 1 || account.isArchive == 2)) {
        account.status = 'Inactive';
        return;
      }
      if (account.status == 1) {
        account.status = 'Active';
        return;
      }
      if (account.status == 0) {
        account.status = 'Processing';
        return;
      }
      if (account.status == 2) {
        account.status = 'Rejected';
        return;
      }
    },
    queryLeverageStatus() {
      apiQueryLeverageStatuses({
        user_id: this.uuid
      }).then(resp => {
        this.leverageStatus = resp.data;
        this.updateLeverageStatus();
        this.seperateAccounts();
      });
    },
    updateLeverageStatus() {
      this.allMetaTraderAccounts.forEach(element => {
        if (element.mt4_account == '-' || element.mt4_account == null) {
          return;
        }
        if (this.leverageStatus[element.mt4_account.toString()]) {
          if (this.leverageStatus[element.mt4_account.toString()].status == 0) {
            element.leverageStatus = 0;
            return;
          }
        }
        element.leverageStatus = 1;
      });
    },
    seperateAccounts() {
      this.mt4AccountTableData = [];
      // this.mt5AccountTableData = [];

      this.allMetaTraderAccounts.map(m => {
        m.platform = m.serverCategory == 4 ? 'MT4' : 'MT5';
        this.mt4AccountTableData.push(m);
      });
      console.log(this.mt4AccountTableData, '//////////////////////');
    },
    showleverageAlreadyInProcessPopUp() {
      this.leverageAlreadyVisible = true;
    },
    // 点击小锁 第一个弹窗
    handleLock(index, row) {
      this.passwordVisible = true;
      this.number = row.mt4_account;
    },
    forgotPwdClick() {
      this.sendResetPasswordEmail().then(result => {
        let vm = this;
        if (result) {
          this.passwordVisible = false;
          this.forgotPwdVisible = true;
        } else {
          this.$message({
            message: vm.$t('home.pw.error'),
            type: 'error'
          });
        }
      });
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname;
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl,
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out');
            return Promise.resolve(true);
          } else {
            console.log('failed to sent out the reset password');
          }
        } else {
          console.log('Errors happened when applying for the reset password emails');
        }
        return Promise.resolve(false);
      });
    },
    openReactivateConfirmationPopUp(metaTraderLogin) {
      this.reActivateMetaTraderLogin = metaTraderLogin;
      this.activateVisible = true;
    },
    promptMessage() {
      const h = this.$createElement;
      const noDepositBonusLink = '/noDepositBonus';
      const content = this.$t('promotion.noDepositBonus.promptMessage', {
        noDepositBonusLink: noDepositBonusLink
      });
      const message = h('div', { domProps: { innerHTML: content } });
      let self = this;

      this.$notify({
        duration: 5000,
        offset: window.innerWidth > 991 ? 320 : 70,
        position: this.$store.state.common.lang !== 'ar' ? 'top-right' : 'top-left',
        message: message,
        onClick: function() {
          const notifyClickHandler = function(e) {
            if (e.target.tagName !== 'A') {
              self.$router.push(noDepositBonusLink);
              self.$notify.closeAll();
            }
            window.removeEventListener('click', notifyClickHandler);
          };
          window.addEventListener('click', notifyClickHandler);
        }
      });
    },
    getNoDespositBonusInfo(noDepositBonusID) {
      apiCampaignInfo(noDepositBonusID)
        .then(resp => {
          this.$store.commit('common/setNoDepositIsLogin', false);
          if (resp.data.code === 0) {
            let data = resp.data.data;
            if (!data.expired && data.participantStatus === 'PENDING') {
              this.$store.commit('promotion/setClaimedStaus', false);
              this.$store.commit('promotion/setClaimedCheckedStaus', false);
              this.promptMessage();
            } else {
              data.participantStatus === 'EXPIRED'
                ? this.$store.commit('promotion/setClaimedCheckedStaus', false)
                : this.$store.commit('promotion/setClaimedCheckedStaus', true);
              this.$store.commit('promotion/setClaimedStaus', true);
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
