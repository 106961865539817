<template>
  <!-- active 弹框开始 -->
  <div class="active_dialog">
    <vDialog :visible.sync="visible" :visibleFooter="true">
      <p>{{ $t('home.activate.question') }}</p>
      <el-button class="purple_button" @click="activeConfirmClick">
        {{ $t('common.button.confirm') }}
      </el-button>
      <el-button class="white_button" @click="disableActivateVisible(false)">
        {{ $t('common.button.cancel') }}
      </el-button>
    </vDialog>
  </div>
  <!-- active 弹框结束 -->
</template>

<script>
import vDialog from './vDialog';
import { apiReactivate_Account } from '@/resource';
export default {
  props: {
    uuid: [Number, String],
    reActivateMetaTraderLogin: Number,
    activateVisible: Boolean,
    activateConfirmVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    activateVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableActivateVisible(bool);
    }
  },
  methods: {
    activeConfirmClick() {
      this.reActivateAccount();
      this.disableActivateVisible(false);
      this.$emit('update:activateConfirmVisible', true);
    },
    disableActivateVisible(bool) {
      this.$emit('update:activateVisible', bool);
    },
    reActivateAccount() {
      apiReactivate_Account({
        user_id: this.uuid,
        metaTraderLogin: this.reActivateMetaTraderLogin
      })
        .then(resp => {
          this.$emit('resetTable');
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/active.scss';
</style>
